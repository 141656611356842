import Vue from 'vue'
import Oruga from '@oruga-ui/oruga'

Vue.use(Oruga, {
  skeleton: {
    override: true,
    rootClass: 'ucsc-skltn',
    itemClass: 'ucsc-skltn__item',
    animationClass: 'ucsc-skltn__item--animated',
    itemRoundedClass: 'ucsc-skltn__item--rounded',
    positionClass: 'ucsc-skltn__item--',
    sizeClass: 'ucsc-skltn__item--',
  },
  tabs: {
    override: true,
    rootClass: 'ucsc-tabs',
    contentClass: 'ucsc-tabs__content',
    navTabsClass: 'ucsc-tabs__nav',
    tabItemWrapperClass: 'ucsc-tabs__navItem',
    itemClass: 'ucsc-tabs__item',
    itemHeaderClass: 'ucsc-tabs__itemHeader-',
    itemHeaderActiveClass: 'ucsc-tabs__itemHeader--active-',
    itemHeaderTextClass: 'ucsc-tabs__itemHeader-text',
    itemHeaderIconClass: 'ucsc-tabs__itemHeader-icon',
    itemHeaderDisabledClass: 'ucsc-tabs__itemHeader--disabled-',
  },
  steps: {
    override: true,
    rootClass: 'ucsc-steps',
    stepsClass: 'ucsc-steps__nav',
    itemClass: 'ucsc-steps__item',
    itemHeaderClass: 'ucsc-steps__header',
    itemHeaderActiveClass: 'ucsc-steps__header--active',
    itemHeaderPreviousClass: 'ucsc-steps__header--previous',
    stepContentClass: 'ucsc-steps__content',
    stepContentTransitioningClass: 'ucsc-steps__content--transition',
    stepMarkerClass: 'ucsc-steps__marker',
    stepNavigationClass: 'ucsc-steps__navigation',
    stepMarkerRoundedClass: 'ucsc-steps__marker--rounded',
    stepLinkClass: 'ucsc-steps__link',
    stepLinkClickableClass: 'ucsc-steps__link--clickable',
    stepLinkLabelClass: 'ucsc-steps__linkLabel',
    animatedClass: 'ucsc-steps--animated',
  },
  tooltip: {
    override: true,
    rootClass: 'ucsc-tip',
    contentClass: 'ucsc-tip__content',
    orderClass: 'ucsc-tip__content--',
    triggerClass: 'ucsc-tip__trigger',
    arrowClass: 'ucsc-tip__arrow',
    arrowOrderClass: 'ucsc-tip__arrow--',
  },
  modal: {
    canCancel: ['escape', 'outside', 'button'],
    override: true,
    rootClass: 'ucsc-modal',
    overlayClass: 'ucsc-modal__overlay',
    contentClass: 'ucsc-modal__container',
  },
  // dropdown: {
  //   override: true,
  //   rootClass: 'ucsc-dropdown',
  //   menuClass: 'ucsc-dropdown__menu',
  //   menuActiveClass: 'ucsc-dropdown__menu--active',
  //   itemClass: 'ucsc-dropdown__item',
  //   itemActiveClass: 'ucsc-dropdown__item--active',
  //   expandedClass: 'ucsc-dropdown--expanded',
  //   disabledClass: 'ucsc-dropdown--disabled',
  // },
  collapse: {
    override: true,
    rootClass: 'ucsc-accordion',
  },
  autocomplete: {
    override: true,
    rootClass: 'ucsc-autocomplete',
    menuClass: 'ucsc-autocomplete__menu',
    menuPositionClass: 'ucsc-autocomplete__menu--',
    expandedClass: 'ucsc-autocomplete__menu--expanded',
    itemGroupTitleClass: 'ucsc-autocomplete__item--title',
    itemClass: 'ucsc-autocomplete__item',
    itemHoverClass: 'ucsc-autocomplete__item--hover',
  },
  field: {
    override: true,
    rootClass: 'ucsc-field',
    labelClass: 'ucsc-field__label',
    messageClass: 'ucsc-field__message',
    variantClass: 'ucsc-field__message--',
  },
  switch: {
    override: true,
    rootClass: 'ucsc-switch',
    variantClass: 'ucsc-switch--',
    labelClass: 'ucsc-switch__label',
    leftLabelClass: 'ucsc-switch__wrapper--leftLabel',
    elementsWrapperClass: 'ucsc-switch__wrapper',
    checkClass: 'ucsc-switch__check',
    checkCheckedClass: 'ucsc-switch__check--checked',
    checkSwitchClass: 'ucsc-switch__check-switch',
  },
  radio: {
    override: true,
    rootClass: 'ucsc-radio',
    checkClass: 'ucsc-radio__check',
    checkCheckedClass: 'ucsc-radio__check--checked',
    checkedClass: 'ucsc-radio--active',
    disabledClass: 'ucsc-radio--disabled',
    labelClass: 'ucsc-radio__label',
    variantClass: 'ucsc-radio--',
  },
  checkbox: {
    override: true,
    rootClass: 'ucsc-checkbox',
    variantClass: 'ucsc-checkbox--',
    checkedClass: 'ucsc-checkbox--checked',
    disabledClass: 'ucsc-checkbox--disabled',
    labelClass: 'ucsc-checkbox__label',
    checkClass: 'ucsc-checkbox__nativeControl',
    checkCheckedClass: 'ucsc-checkbox__nativeControl--checked',
  },
  input: {
    override: true,
    inputClass: 'ucsc-input',
    iconRightClass: 'ucsc-input__icon',
  },
  button: {
    override: true,
    rootClass: 'ucsc-button',
    variantClass: 'ucsc-button--',
    disabledClass: 'ucsc-button--disabled',
  },
  pagination: {
    override: true,
    rootClass: 'ucsc-pagination',
    orderClass: 'ucsc-pagination--',
    listClass: 'ucsc-pagination__list',
    linkClass: 'ucsc-pagination__link',
    linkCurrentClass: 'ucsc-pagination__link--current',
    prevBtnClass: 'ucsc-pagination__link--prev',
    nextBtnClass: 'ucsc-pagination__link--next',
    linkDisabledClass: 'ucsc-pagination__link--disabled',
    ellipsisClass: 'ucsc-pagination__ellipsis',
  },
})
