/* eslint-disable no-undef */
export default ({ app, $tealium }) => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker.register('/service-worker.js')
    })
  }

  app.router.afterEach((to, from) => {
    if (from.name) return // if the navigation is JS we don't want to reinitialize the listener

    window.deferredPrompt = undefined
    window.addEventListener('beforeinstallprompt', (e) => {
      // Stash the event so it can be triggered later.
      window.deferredPrompt = e
    })

    window.addEventListener('appinstalled', () => {
      $tealium.track({
        event_name: 'banner_home_aggiungi',
      })

      // Clear the deferredPrompt so it can be garbage collected
      window.deferredPrompt = null
    })
  })
}
