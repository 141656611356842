async function createPersistedProperties(store) {
  const module = await import('vuex-persist')
  const VuexPersistence = module.default
  new VuexPersistence({
    key: 'properties',
    modules: ['properties'],
  }).plugin(store)
}

export default ({ store }) => {
  createPersistedProperties(store)
}
