async function createPersistedInteractions(store) {
  const module = await import('vuex-persist')
  const VuexPersistence = module.default
  new VuexPersistence({
    key: 'interactions',
    modules: ['interactions'],
  }).plugin(store)
}

export default ({ store }) => {
  createPersistedInteractions(store)
}
